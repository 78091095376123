<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_assets_registration_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" v-permission="['pc_assets_registration_export']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<!-- <a-button v-permission="['pc_assets_registration_export']">
						<template #icon><Icon icon="UploadOutlined"/></template>
						导出</a-button>
					<a-button v-permission="['pc_assets_registration_export']">
						<template #icon><Icon icon="LogoutOutlined"/></template>
						导出全部</a-button> -->
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<a-button v-permission="['pc_assets_registration_apply']" type="primary" @click="onApply">申请下账</a-button>
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="资产编号" class="ui-form__item">
						<a-row>
							<a-col :span="11">
								<a-input v-model:value="formModal.startCode"></a-input>
							</a-col>
							<a-col :span="2" class="ui-form__middle">到</a-col>
							<a-col :span="11">
								<a-input v-model:value="formModal.endCode"></a-input>
							</a-col>
						</a-row>
					</a-form-item>
					<a-form-item label="资产名称" class="ui-form__item">
						<a-input v-model:value="formModal.assetsName"></a-input>
					</a-form-item>
					<a-form-item label="资产分类" class="ui-form__item">
						<a-cascader v-model:value="formModal.assetsType" :options="$store.state.count.assetClassify" placeholder="请选择资产分类" />
						<!-- <a-select  allow-clear v-model:value="formModal.assetsType" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.assetClassify" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select> -->
					</a-form-item>
					<a-form-item label="下账原因" class="ui-form__item">
						<!-- <a-input v-model:value="formModal.keepAccountsType"></a-input> -->
						<a-select v-model:value="formModal.keepAccountsType" allow-clear style="width: 190px;">
							<a-select-option :value="1">盘亏</a-select-option>
							<a-select-option :value="2">损坏</a-select-option>
							<a-select-option :value="3">超过使用年限</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="会计凭证号" class="ui-form__item">
						<a-input v-model:value="formModal.accountantNum"></a-input>
					</a-form-item>
					<a-form-item label="使用部门" class="ui-form__item">
						<a-select  allow-clear v-model:value="formModal.departmentId" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="盘点人" class="ui-form__item">
						<a-input v-model:value="formModal.checker"></a-input>
					</a-form-item>
					<a-form-item label="状态" class="ui-form__item">
						<a-select  allow-clear v-model:value="formModal.status" style="width: 190px;">
							<a-select-option :value="-1">全部</a-select-option>
							<a-select-option :value="1">已确认</a-select-option>
							<a-select-option :value="0">未确认</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="申请时间段" class="ui-form__item">
						<a-range-picker v-model:value="time" show-time />
					</a-form-item>
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'assetsName'">资产名称</a-checkbox>
							<a-checkbox :value="'assetsNum'">资产编号</a-checkbox>
							<a-checkbox :value="'accountantNum'">会计凭证号</a-checkbox>
							<a-checkbox :value="'applyTime'">申请时间</a-checkbox>
							<a-checkbox :value="'assetsType'">资产分类</a-checkbox>
							<a-checkbox :value="'state'">状态</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.applyTime) }}
						</template>
						<template v-if="column.key === 'applyInfo'">
							<a v-if="record.applyInfo.length !== 0" :href="record.applyInfo">查看资料</a>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status === '1'">已确认</div>
							<div v-else>未确认</div>
						</template>
						<template v-if="column.key === 'keepAccountsType'">
							<div v-if="record.keepAccountsType === '1'">盘亏</div>
							<div v-if="record.keepAccountsType === '2'">损坏</div>
							<div v-if="record.keepAccountsType === '3'">超过使用年限</div>
						</template>
						<template v-if="column.key === 'action'">
							<!-- <a-button v-permission="['pc_assets_registration_return']" type="link" @click="onCancel(record)" size="small">撤销下账</a-button> -->
							<a-button  v-permission="['pc_assets_registration_return']" type="link" @click="onCancel(record)" size="small">
								{{ record.status === "1" ? '撤销下账' : '撤销下账申请' }}
							</a-button>
						</template>
					</template>
				</c-Table>
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.time) }}
						</template>
						<template v-if="column.key === 'applyInfo'">
							<a v-if="record.applyInfo.length !== 0" :href="record.applyInfo">查看资料</a>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status === '1'">是</div>
							<div v-else>否</div>
						</template>
						<template v-if="column.key === 'keepAccountsType'">
							<div v-if="record.keepAccountsType === '1'">盘亏</div>
							<div v-if="record.keepAccountsType === '2'">损坏</div>
							<div v-if="record.keepAccountsType === '3'">超过使用年限</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_assets_registration_return']" type="link" @click="onCancel(record)" size="small">撤销下账</a-button>
							<span></span>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<lossApplyModal ref="lossApplyModalRef" @refresh="onReset"></lossApplyModal>
	</div>
</template>

<script>
	import { getAccountsList, revokeKeepAccounts } from '@/service/modules/property.js';
	import { Icon } from '@/components/icon/icon.js';
	import lossApplyModal from './components/lossApplyModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, lossApplyModal, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				searchData: {},
				formModal: {},
				time: [],
				force: 0, // 用于传给后台的撤销状态值
				getDataFun: getAccountsList,
				list: [],
				type: 'keepAccountsStrategy', // 导出类型
				role: 0, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				sortList:[],
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index'
				}, {
					title: '资产分类',
					align: 'center',
					dataIndex: "assetsType"
				}, {
					title: '资产编号',
					align: 'center',
					dataIndex: "assetsNum"
				}, {
					title: '资产名称',
					align: 'center',
					dataIndex: "assetsName"
				}, {
					title: '下账原因',
					align: 'center',
					key: 'keepAccountsType',
					// dataIndex: "keepAccountsType"
				}, {
					title: '补充说明',
					align: 'center',
					dataIndex: "description"
				}, {
					title: '会计凭证号',
					align: 'center',
					dataIndex: "accountantNum"
				}, {
					title: '使用部门',
					align: 'center',
					dataIndex: "departmentName"
				}, {
					title: '盘点人',
					align: 'center',
					dataIndex: "checker"
				}, {
					title: '申请资料',
					align: 'center',
					key: 'applyInfo',
					// dataIndex: "applyInfo",
				}, {
					title: '申请时间',
					align: 'center',
					key: 'time'
				}, {
					title: '状态',
					align: 'center',
					key: 'status'
				}, {
					title: '操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
			}
		},
		created() {
			this.onSearch()
		},	
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getAccountsList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("accountsLis",ret)
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onReset() {
				this.$refs.formRef.resetFields();
				this.time = [];
				this.formModal = {}
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));
				if (this.time && this.time.length) {
					this.searchData.startTime = this.time[0].unix();
					this.searchData.endTime = this.time[1].unix();
				}
				this.searchData.assetsType = this.searchData.assetsType ? this.searchData.assetsType[this.searchData.assetsType.length - 1] : undefined;
				// this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onApply() {
				this.$refs.lossApplyModalRef.open();
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onChange(date, dateString) { // 时间变化的回调
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  // console.log(starDate.valueOf(), endDate.valueOf());
			  this.formModal.startTime = starDate.valueOf()/1000
			  this.formModal.endTime = endDate.valueOf()/1000
			},
			onCancel(item) {
				this.$confirm({
					title: '提示',
					content: "确定撤销吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await revokeKeepAccounts({
								id: item.id,
								force: this.force
							})
							this.loading = false;
							if(ret.code === 408) {
								setTimeout(()=> {
									this.onConfirmCancel(item)
								},500)
							}
							if (ret.code === 200) {
								this.$message.success('撤销成功');
								this.onSearch();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onConfirmCancel(item) {
				this.$confirm({
					title: '提示',
					content: "该报废已通过审核，是否确认撤销？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await revokeKeepAccounts({
								id: item.id,
								force: 1
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('已提交撤销申请');
								this.onSearch();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onSortClick(type){
				let list = [];
				console.log(this.formModal.sort);
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});				
				this.formModal.sort = list;
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
			// onCancelConfirm() {
			// 	this.force = 1
			// 	this.$confirm({
			// 		title: '提示',
			// 		content: "确定撤销吗？",
			// 		onOk: async ()=> {
			// 			this.loading = true;
			// 			try {
			// 				let ret = await revokeKeepAccounts({
			// 					id: item.id,
			// 					force: this.force
			// 				})
			// 				this.loading = false;
			// 				if (ret.code === 200) {
			// 					this.$message.success('撤销成功');
			// 					this.getData();
			// 				}
			// 			} catch(e) {
			// 				this.loading = false;
			// 			}
			// 		}
			// 	})
			// }
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>